.footer-wrapper {
    background: #ffffff;
    border-radius: 30px 30px 0 0;
    margin-top: 20px;

    @media (min-width: 250px) and (max-width: 766px) {
        padding-top: 34px;
    }
}
.footer-block {
    max-width: 1300px;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    @media (min-width: 250px) and (max-width: 1400px) {
        height: unset;
        padding: 0 20px;
    }
    &__final-text{
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #3D515F;
        padding: 15px;
        border-top: 1px solid grey;
    }
    &__text {
        @media (min-width: 250px) and (max-width: 766px) {
            text-align: center;
        }
    }
    &__title {
        font-family: Raleway,sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.05em;
        color: #182837;

        @media (min-width: 250px) and (max-width: 600px) {
            width: unset;
            font-size: 20px;
        }
    }

    &__reasons-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include max-width-container{
        flex-direction: column;
        }
    }
    &__reasons-logo{
            order: 1;
        @include max-width-container{
      order: 2;
            margin-top: 30px;
        }
    }
    &__reasons{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 65px;        order: 2;
        @include max-width-container{
            order: 1;
            display: block;
        }
    }

    &__reason{
     @include max-width-container{
         margin-top: 24px;
     }
        &_wrapper{
            display: grid;
            grid-gap: 20px;

            margin-left: auto;
            align-items: center;
        grid-template-columns: 71px 255px;
        }
        &_logo{
            width: 71px;
            height:71px;

            background: #7AC362;
            border-radius: 18px;
            background:  url("../images/Rectangle.svg") no-repeat;
            position: relative;
            &:before{
                background:  url("../images/Vector.svg") no-repeat center;
                width: 32px;
                height: 24px;
                content: "";
                position: absolute;

                left: 20px;
                top:23px;
            }

        }
    }
    &__sub-title{
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;

        /* identical to box height */

        color: #000000;
        margin-bottom: 10px;
    }
    &__description{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;

        color: #525252;


    }
}
.footer-social {
    display: flex;
    margin: 15px auto;
    justify-content: space-around!important;
    width: 394px;

    @media (min-width: 250px) and (max-width: 766px) {
        width: 100%;
        margin: 20px auto
    }
    &__item {
        //margin-left: 15px;
    }
    img {
        width: 32px;
        height: 32px;

        @media (min-width: 250px) and (max-width: 766px) {
            width: 46px;
            height: 46px;
        }
    }
}
.footer-content-left {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.footer-content-right {
    @media (min-width: 250px) and (max-width: 822px) {
        display: none;
    }
}

