.store-app-block {
    background: #fff;

    &__wrapper {
        max-width: 1300px;
        padding: 40px 0;
        margin: 20px auto;

        @media (min-width: 767px) and (max-width: 1024px) {
            max-width: 900px;
            //width: 508px;
        }
    }

    &__business-women {
        @media (min-width: 320px) and (max-width: 766px) {
            display: none;
        }

    }
    &__business-women-mobile {
        @media (min-width: 767px) and (max-width: 3000px) {
            display: none;
        }
    }

    &__top-download-block {
        display: flex;
        justify-content: space-between;
        background: #fff;
        -webkit-box-shadow: 0 4px 20px #e3e4ef;
        box-shadow: 0 4px 20px #e3e4ef;
        overflow: auto;

        @media (min-width: 359px) and (max-width: 766px) {
            font-size: 16px;
            height: 147px;
        }
        &__left {
            padding: 20px 0px 0px 36px;

            @media (min-width: 767px) and (max-width: 1024px) {
                width: unset;
                //width: 508px;
            }
        }
        &__right {
            img {
                @media (min-width: 250px) and (max-width: 766px) {
                    width: 140px;
                }
            }
        }
    }
    &__average-download-block {
        display: flex;
        justify-content: space-between;
        height: 100px;
        border: 1px solid #DDDDDD;
        border-radius: 5px;
        margin: 60px 15px;

        @media (min-width: 250px) and (max-width: 766px) {
            display: block;
            height: 165px;
            margin: 20px;
        }
        &__left {
            display: flex;
        }
        &__right {
            margin-right: 20px;
            padding-top: 23px;
            @media (min-width: 250px) and (max-width: 825px) {
                margin-left: 10px;
            }
        }
    }
    &__btn-app-store {

    }
    &__describe-title {
        display: block;
        margin-left: 10px;
        &__title {
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 120%;
            color: #021229;
            margin-top: 19px;
            @media (min-width: 250px) and (max-width: 825px) {
                width: 309px;
                text-align: left;
            }
        }
        &__text {
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 130%;
            color: #4D5255;
            width: 418px;
            margin-top: 4px;
            @media (min-width: 250px) and (max-width: 825px) {
                display: none;
            }
        }
    }
    &__fox-picture {
        padding: 8px 32px;
        @media (min-width: 250px) and (max-width: 825px) {
            display: none;
        }
    }
    &__title {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 120%;
        color: #021229;
        @media (min-width: 250px) and (max-width: 766px) {
            font-size: 14px;
        }
        @media (min-width: 767px) and (max-width: 1024px) {
            font-size: 24px;
        }
    }
    &__description {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: #4D5255;
        margin-top: 16px;
        @media (min-width: 250px) and (max-width: 766px) {
            display: none;
        }
        @media (min-width: 767px) and (max-width: 1024px) {
            font-size: 14px;
        }
    }
    &__title-box{
        margin-top: auto;
    }
    &__button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px 91.5px;
        background: #2D81E0;
        border-radius: 8px;
        border: none;
        margin-top: 24px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        width: 246px;

        @media (min-width: 359px) and (max-width: 766px) {
            font-size: 16px;
            padding: 10px 0;
            width: unset;
            margin-top: 18px;
        }
        @media (min-width: 767px) and (max-width: 1024px) {
            width: 120px;
        }
    }
    &__bottom-download-block {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 10px;
        overflow: auto;
        margin: 0 15px;

        @media (min-width: 359px) and (max-width: 766px) {
            justify-content: center;
            grid-template-columns: unset;
        }
        //@media (min-width: 767px) and (max-width: 1400px) {
        //    justify-content: space-evenly;
        //    overflow: auto;
        //    flex-wrap: wrap;
        //}
    }
    &__advertising-block {
        width: 278px;
        background: #FFFFFF;
        border: 1px solid #BABABA;
        border-radius: 5px;

        @media (min-width: 359px) and (max-width: 766px) {
            height: unset;
            margin: 0 auto;

        }
    }
    &__grey-advertising-block {
        width: 278px;
        background: #FFFFFF;
        border: 1px solid #BABABA;
        border-radius: 5px;

        @media (min-width: 359px) and (max-width: 766px) {
           display: none;

        }
    }
    &__adv-title {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        text-align: center;
        color: #021229;
        margin-top: 49px;

        @media (min-width: 359px) and (max-width: 766px) {
            margin-top: 20px;
        }
    }
    &__adv-describe {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        text-align: center;
        color: #4D5255;
        margin-top: 12px;
    }
    &__adv-button {
        margin: 24px 39px;
        @media (min-width: 359px) and (max-width: 766px) {
            text-align: center;
        }
    }
}
