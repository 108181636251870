@mixin flex($jc:flex-start, $ai:flex-start, $wrap: wrap) {
    display: flex;
    justify-content: $jc;
    align-items: $ai;
    flex-wrap: $wrap
}

@mixin list-unstyled {
    list-style: none;
    padding: 0;
    margin: 0;
}