body,
html {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background: #FFF;
    color: #000;
    font-family: $font-family;
    font-size: 16px;
}

body {
    overflow: hidden;
    overflow-y: auto;
}

/* h1, h2, h3, h4 {
    font-family: 'Rubik', sans-serif;
} */

a {
    color: inherit;
    text-decoration: none
}
