.menu-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    &__logo-block {
        display: flex;
        align-items: center;
    }
    @media (min-width: 359px) and (max-width: 766px) {
        justify-content: center;
    }
}

.menu-logo {
    display: flex;

    :first-child {
        margin-right: 10px;
    }
    &__text {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #182837;
    }

}

.menu-social {
    display: flex;
    align-items: center;

    &__item {
        margin-left: 20px;
        margin-top: 5px;
    }
    &__text-block {
        display: flex;
        flex-direction: column;
    }
    &__icons {
        display: flex;
    }
    @media (min-width: 359px) and (max-width: 766px) {
        display: none;
    }

}


