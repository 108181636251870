body, html {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    color: #000;
    font-family: $font-family;
    font-size: 16px;
    background: #F4F5FF;
}
