.filter-block {
    display: flex;
    align-items: center;
    background: #ffffff;
    background-size: cover;
    height: 629px;
    border-radius: 0 0 30px 30px;
    justify-content: center;
    text-align: center;

    @media (min-width: 359px) and (max-width: 766px) {
        height: 160px;
    }
    &__title {
        color: #FFFFFF;
        font-weight: 700;
        font-size: 36px;
        text-align: center;

        @media (min-width: 359px) and (max-width: 766px) {
            font-size: 24px;
        }
    }
    &__text {
        width: 388px;
        height: 44px;
        text-align: center;
        font-weight: 400;
        color: #FFFFFF;
        margin: 0 auto;
        padding-top: 20px;
        @media (min-width: 359px) and (max-width: 766px) {
            width: 343px;
            font-size: 14px;
            padding-top: 16px;
        }
    }
    &__search {
        display: flex;
        padding-top: 30px;
        @media (min-width: 359px) and (max-width: 766px) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }
    &__input-field-radius {
        width: 310px;
        height: 50px;
        background: #EFF9FE;
        margin-left: -120px;
        border: 1px solid #096BC8;
        box-sizing: border-box;
        text-indent: 15px;
        border-radius: 10px 0 0 10px;
        @media (min-width: 359px) and (max-width: 766px) {
            width: 345px;
            border-radius: 10px 10px 0 0;
            margin-left: 0;
        }
        @media (min-width: 767px) and (max-width: 1023px) {
            width: 161px;
            margin-left: 0;
        }
        @media (min-width: 1024px) and (max-width: 1600px) {
            width: 245px;
            margin-left: 2px;
        }
    }
    &__input-field-no-radius {
        width: 310px;
        height: 50px;
        background: #EFF9FE;
        border: 1px solid #096BC8;
        box-sizing: border-box;
        text-indent: 15px;
        @media (min-width: 359px) and (max-width: 766px) {
            width: 345px;
        }
        @media (min-width: 767px) and (max-width: 1023px) {
            width: 181px;
        }
        @media (min-width: 1024px) and (max-width: 1600px) {
            width: 225px;
        }
    }
    &__button {
        width: 277px;
        height: 50px;
        background: #66BB6A;
        color: #FFFFFF;
        font-family: $font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        border: none;
        border-radius: 0 10px 10px 0;
        @media (min-width: 359px) and (max-width: 766px) {
            width: 345px;
            border-radius: 0 0 10px 10px;
        }
        @media (min-width: 767px) and (max-width: 1023px) {
            width: 181px;
        }
        @media (min-width: 1024px) and (max-width: 1600px) {
            width: 245px;
        }
    }
}
::placeholder {
    color: #9F9F9F;
    margin-left: 30px;
}
