.credit-widget-block {
    background: #fff;

    &__wrapper {
        max-width: 1300px;
        margin: 0 auto;
        padding-top: 55px;
    }

    &__top-download-block {
        background: #fff;
        border: 1px solid #BABABA;
        max-width: 1300px;
        overflow-x: auto;
        overflow-y: hidden;
        height: 224px;


        @media (min-width: 359px) and (max-width: 1024px) {
            overflow-x: auto;
        }
        @media (min-width: 359px) and (max-width: 766px) {
            font-size: 16px;
            height: 220px;
            padding: 20px;
        }
        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            //height: 266px;

            @media (min-width: 350px) and (max-width: 766px) {
                justify-content: center;
            }
            @media (min-width: 767px) and (max-width: 1024px) {
                justify-content: space-evenly;
            }
        }
        &__left {
            margin-right: 35px;
            @media (min-width: 250px) and (max-width: 766px) {
                margin-right: 0;
            }
            //@media (min-width: 767px) and (max-width: 1180px) {
            //    margin-top: 45px;
            //}
        }
        &__right {
            padding-bottom: 70px;
            margin-right: 30px;
            @media (min-width: 250px) and (max-width: 766px) {
                padding: 0px 20px;
                margin-right: unset;
            }
        }
        &__bottom {
            @media (min-width: 767px) and (max-width: 3000px) {
                display: none;
            }
        }
    }
    &__average-download-block {
        display: flex;
        justify-content: space-between;
        height: 147px;
        border: 1px solid #DDDDDD;
        border-radius: 5px;
        margin: 60px auto;

        @media (min-width: 250px) and (max-width: 766px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 165px;
            margin: 20px;
        }
        &__left {
            display: flex;
        }
    }
    &__girl-picture {
        @media (min-width: 250px) and (max-width: 825px) {
            display: none;
        }
    }
    &__title {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 120%;
        color: #021229;

        @media (min-width: 250px) and (max-width: 766px) {
            font-size: 16px;
        }
        @media (min-width: 767px) and (max-width: 1180px) {
           font-size: 32px;
            margin-top: 45px;
        }
    }
    &__description {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: #4D5255;
        margin-top: 16px;
        @media (min-width: 250px) and (max-width: 766px) {
            font-size: 14px;
            line-height: 130%;
            width: 182px;
            margin-top: 8px;
        }
    }
    &__title-box{
        margin-top: auto;
        @media (min-width: 250px) and (max-width: 766px) {
            display: none;
        }
    }
    &__average-title-box{
        margin-top: auto;
    }
    &__describe-title {
        display: block;
        margin-left: 45px;
        @media (min-width: 250px) and (max-width: 766px) {
            margin-left: unset;
        }
        &__title {
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 120%;
            color: #021229;
            margin-top: 45px;
            @media (min-width: 250px) and (max-width: 1024px) {
                text-align: center;
                margin-top: 30px;
            }
        }
        &__text {
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 130%;
            color: #4D5255;
            width: 445px;
            margin-top: 4px;
            @media (min-width: 250px) and (max-width: 766px) {
                display: none;
            }
            @media (min-width: 767px) and (max-width: 1024px) {
                width: 275px;
            }
        }
    }
    &__button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px 91.5px;
        background: #2D81E0;
        border-radius: 8px;
        border: none;
        margin-top: 24px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        width: 173px;

        @media (min-width: 359px) and (max-width: 766px) {
            font-size: 16px;
            width: unset;
            padding: 15px 20px;
        }

        @media (min-width: 767px) and (max-width: 1023px) {
            height: 13px;
            font-size: 16px;
        }
    }
    &__btn-average {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px 91.5px;
        background: #2D81E0;
        border-radius: 8px;
        border: none;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        width: 150px;
        margin-right: 32px;
        margin-top: 46px;

        @media (min-width: 359px) and (max-width: 766px) {
            font-size: 16px;
            width: unset;
            margin: 20px;
            padding: 15px 20px;
        }

        @media (min-width: 767px) and (max-width: 1023px) {
            height: 13px;
        }
    }
    &__bottom-download-block {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 15px;
        overflow: auto;
        margin: 0 auto;
        padding-bottom: 100px;

        @media (min-width: 359px) and (max-width: 766px) {
            justify-content: center;
            grid-template-columns: unset;
        }
    }
    &__advertising-block {
        width: 310px;
        height: 332px;
        background: #FFFFFF;
        border: 1px solid #BABABA;
        border-radius: 5px;

        @media (min-width: 359px) and (max-width: 766px) {
            margin: 0 20px 40px;
        }
    }
    &__adv-picture img {
        width: 100%;
    }
    &__grey-advertising-block {
        width: 310px;
        height: 332px;
        background: #FFFFFF;
        border: 1px solid #BABABA;
        border-radius: 5px;
        @media (min-width: 359px) and (max-width: 766px) {
            display: none;
        }
    }
    &__adv-title {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        text-align: center;
        color: #021229;
        margin: 7px 10px;
    }
    &__adv-describe {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        text-align: center;
        color: #4D5255;
        margin: 0 40px;

    }
    &__adv-button {
        margin: 18px 25px;
    }
    &__advertising-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px 43px;
        background: #2D81E0;
        border-radius: 8px;
        border: none;
        margin-top: 24px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        width: 173px;
        @media (min-width: 359px) and (max-width: 766px) {
            font-size: 16px;
            margin-top: 20px;
            width: unset;
            padding: 15px 0;
        }
        @media (min-width: 767px) and (max-width: 1023px) {
            height: 13px;
            font-size: 16px;
        }
    }
}

.girlWithoutFace {
    //@media (min-width: 767px) and (max-width: 3000px) {
    //    display: none;
    //}
    @media (min-width: 250px) and (max-width: 1179px) {
        display: none;
    }
    &__mobile {
        @media (min-width: 1180px) and (max-width: 3000px) {
            display: none;
        }
    }
}
.button-credit-mobile {
    @media (min-width: 767px) and (max-width: 3000px) {
        display: none;
    }
}

