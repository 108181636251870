.slider-offers {
    &__item{
   .item{
       margin: 10px;
   }
    }
    &__title {
        font-family: $font-family;
        font-style: normal;
        font-weight: bold;
        line-height: 120%;
        text-align: center;
        color: #303446;
        margin: 20px 0;
        @include fluidFontSize(24px, 36px, 375px, 1400px, 36px);
        @media (min-width: 359px) and (max-width: 766px) {
            font-size: 24px;
        }
    }
    &__title-desktop {
        @media (min-width: 359px) and (max-width: 766px) {
            display: none
        }
    }
    &__title-mobile {
        display: none;
        @media (min-width: 359px) and (max-width: 766px) {
            display: block;
        }
    }
    &__sub-title {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #A8A8A8;
    }
    &__details-wrap{
        @include pLeft(20px, 73px, 375px, 1400px, 73px);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include pRight(20px, 73px, 375px, 1400px, 73px);
        @include pTop(20px, 20px, 375px, 1400px, 20px);
        @include pBottom(20px, 20px, 375px, 1400px, 20px);
        box-sizing: border-box;
       @include max-width-container{
          display: block;
        }

    }

    &__details {
        display: flex;
        justify-content: center;
        align-items: center;

        border: double 0.13em transparent;
        border-radius: 15px;
        background-image: linear-gradient(white, white),
        linear-gradient(to right, #66BB6A, #50AAFF);
        background-origin: border-box;
        background-clip: content-box, border-box;
        box-shadow: 0px 4px 10px rgba(0, 15, 67, 0.1);

    }
    &__wrap {

    }
    &__arrow-right {
        color: black;
        position: absolute;
        right: -30px;
        width: 20px;
        top: 55%;
        cursor: pointer;
        height: 20px;
        z-index: 2;
        box-sizing: border-box;
        @include max-width-container{
            display: none;
        }
    }
    &__arrow-left {
        color: black;
        position: absolute;
        left: -20px;
        width: 20px;
        top: 55%;       cursor: pointer;
        height: 20px;
        box-sizing: border-box;

        @include max-width-container{
            display: none;
        }
    }
    &__best-offer {
        position: relative;
        width: 167px;
        height: 32px;
        margin-top: -30px;
        /* float: left; */
        /* top: -32px; */
        left: 37px;
        border-radius: 5px 5px 0 0;
        background-color: #66BB6A;

    }
    &__best-offer:before {
        position: absolute;
        content: '';
        top: 0;
        left: 165px;
        width: 0;
        height: 0;
        border-top: 32px solid  transparent;
        border-left: 15px solid  #66BB6A;;
    }
    &__best-offer:after {
        position: absolute;
        content: '';
        top: 0;
        right: 165px;
        width: 0;
        height: 0;
        border-top: 32px solid  transparent;
        border-right: 15px solid  #66BB6A;
    }
    &__best-offer-text {
        font-family: $font-family;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #FFFFFF;
        padding-top: 5px;
    }
    &__offer-logo {
        img{
            max-width: 8vw;
            max-height: 6.6vw;
            display: block;
            @include max-width-container{
                max-width: 50%;
                max-height: inherit;
                margin-bottom: 10px;
            }
        }


    }

    &__stat-information {
    display: grid;
        align-items: center;
        @include gridGap(20px, 40px, 375px, 1600px, 40px);
        grid-template-columns: 1fr 1fr 1fr;

  @include max-width-mobile{

  }
    }
    &__info {
        display: block;
    }
    &__count {
        font-family: $font-family;
        font-style: normal;
        font-weight: normal;
        line-height: 33px;
        color: #272727;
        @include fluidFontSize(14px, 24px, 375px, 1400px, 24px);

    }
    &__button {
        @include max-width-container{
          margin-top: 16px;
        }

        //width: 214px;
        height: 45px;
        border: none;
        border-radius: 8px;
        background: #7AC362;
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        padding-right: 23px;
        padding-left: 23px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    &__slider-dots {

    }
    &__dots {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        margin-left: 8px;
        background: #C4C4C4;
    }
    &__content {
     padding-top: 40px;
    }

    .slick-dots{
        justify-content: center;
        display: flex;
        position: relative;

        li{
            list-style: none;
            button{
                text-indent: -9999px;
                width: 10px;
                height: 10px;
                cursor: pointer;
                border-radius: 500px;
                background: #C4C4C4;
                border: none;
                margin-left: 4px;
                margin-right: 4px;
            }

            &.slick-active{
                button{
                    background: #7AC362;
                }
            }
        }
    }
}
