.list-of-loans {
    display: flex;
    //grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    //grid-gap: 30px;
    //align-items: stretch;
    justify-content: space-between;
    //margin: 0 auto;
    &__head-container {
        display: flex;
        align-items: center;
        position: relative;
    }
    &__head-container-turbozaim {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    &__fire {
        position: absolute;
        right: 0;
        margin-right: 13px;
    }
    &__flex-start {
        grid-gap: 21px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;;

        @media (min-width: 767px) and (max-width: 1181px) {
            margin: 0 25px;
        }
        @include max-width-wrapper{
            max-width: 100%;
            box-sizing: border-box; grid-template-columns: 1fr 1fr 1fr;
        }

        @include max-width-container{
            grid-template-columns: 1fr 1fr;
        }

        @include max-width-mobile-phone{
            grid-template-columns: 1fr;
        }
    }
    &__loan {
        &.none{
            display: none;
        }

        background: #FFFFFF;
        border: 1px solid #E1E8DC;
        box-sizing: border-box;
        border-radius: 16px;

          position: relative;
        @include max-width-mobile-phone{

        }
    }
    &__content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        /* width: 165px; */
        padding: 24px;

        box-sizing: border-box;
        height: 100%;

    }
    &__title-box{
        margin-top: auto;
    }

    &__title {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        margin-bottom: 16px;
        line-height: 15px;
        letter-spacing: 0.03em;
        color: #66BB6A;
        @include max-width-container{
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;

           text-align:center;

            color: #453F53;
        }
    }
    &__logo {
        width: 100%;
        img{
            max-width: 50%;
            margin-top: 0;
            margin-left: auto;
            margin-bottom: 0;
            display: block;
            margin-right: auto;
            @include max-width-container{

            }

        }

    }
    &__logo-turbozaim {
        img{
            max-width: 100%;
            margin-top: 10px;
            margin-left: auto;
            margin-bottom: 10px;
            display: block;
            margin-right: auto;
            @include max-width-container{

            }

        }
    }
    &__info {
        display: flex;
        justify-content: center;
        margin-top: 32px;
        margin-bottom: 16px;
        align-items: baseline;
        @include max-width-container{
            display: flex;
        }

        &_container{
            margin-top: auto;
        }
        &_wrapper{

            @include max-width-container{
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            @include max-width-container-xs{


            }
            @media (min-width: 250px) and (max-width: 1200px) {
                display: block;
            }

        }
            &.mobile-none{
                @include max-width-container{
                   display: none;
                }
            }
    }
    &__info-title {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #818C99;
        @media (min-width: 359px) and (max-width: 766px) {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
        }
        @media (min-width: 767px) and (max-width: 1023px) {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
        }
    }
    &__info-count {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 25px;
        margin-left: 10px;
        color: #453F53;
        @include max-width-container{
            //margin-left: 0;
        }
        @media (min-width: 767px) and (max-width: 1023px) {
            font-size: 24px;
        }
    }
    &__info-terms {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }
    &__term-left {
        display: block;
        margin-left: 10px;
    }
    &__term-right {
        display: block;
        margin-right: 10px;
    }
    &__term-title {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #818C99;
    }
    &__term-age {
        margin-top: 6px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 120%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #182837;
        @media (min-width: 767px) and (max-width: 1023px) {
            font-size: 20px;
        }
    }
    &__hd-small {
        @media (min-width: 359px) and (max-width: 766px) {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
        }
        @media (min-width: 767px) and (max-width: 1023px) {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
        }
    }
    &__info-no-bold {
        font-family: $font-family;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        color: #453F53;
        @include max-width-container{
            margin-left: 0;
        }
    }
    &__button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px 57px;
        background: #FFAB49;
        border-radius: 12px;
        border: none;
        margin-top: 24px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        height: 20px;

        @media (min-width: 359px) and (max-width: 766px) {
            height: 20px;
            font-size: 16px;

        }
        @media (min-width: 767px) and (max-width: 1023px) {
            height: 13px;
            font-size: 16px;

        }
    }

    &__percent{
        background: url("../images/Star.svg") no-repeat;
        width: 49px;
        height: 47px;
        position: absolute;
        right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family:$font-family ;
        top: 5px;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 22px;

        /* identical to box height */
        letter-spacing: 0.03em;
    text-align: center;
        color: #FFFFFF;


    }
}
