/*WIDTH*/
$widthMap: (100, 50, 33, 30, 25);
@each $width in $widthMap {
    .w-#{$width} {
        width: $width * 1%;
    }
}

/* FLOAT */
.float {
    &-left {
        float: left
    }
    &-right {
        float: right
    }
    &-none {
        float: none
    }
}

.clr {
    display: block;
    width: 100%;
    height: 0;
    clear: both
}


.hidden {
    display: none
}

.list-unstyled {
    list-style: none;
    padding: 0;
    margin: 0;
}

.hidden {
    display: none !important;
}

.placeholder-unstyled[type="text"] {
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-moz-placeholder,
    &:-ms-input-placeholder {
        color: inherit;
    }
}

.overflow {
    &-hidden {
        overflow: hidden;
    }
}


/* Text Styles */
.text {
    &-left {
        text-align: right;
    }
    &-center {
        text-align: center;
    }
    &-right {
        text-align: right;
    }
}

.overflow-ellipsis {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.uppercase {
    text-transform: uppercase;
}