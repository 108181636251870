.fixed-overlay {
    display: none;
    position: fixed;
    overflow: auto;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 10000;
}

.modal-window {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @media (min-width: 320px) and (max-width: 766px) {
        width: 100%;
    }
}

.pop-up-widget {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #FCFDFF;
    border-radius: 20px;
    margin: 24px auto;

    @media (min-width: 320px) and (max-width: 766px) {
        max-width: 328px;
    }

    &__top-text {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 700;
        text-align: center;
        letter-spacing: 0.03em;
        color: #181818;

        span {
            color: #03a7f1;
        }

        &__mobile {
            font-size: 24px;
            line-height: 28px;
            padding: 32px 24px 0px 24px;

            @media (min-width: 767px)  {
                display: none;
            }
        }

        &__desktop {
            font-size: 32px;
            line-height: 38px;
            padding: 32px 24px 0 24px;

            @media (min-width: 320px) and (max-width: 766px) {
                display: none;
            }
        }

    }
    &__showcase-block {
        grid-gap: 21px;
        display: flex;
        justify-content: center;
        margin: 36px 98px;
        overflow: auto;

        @media (min-width: 320px) and (max-width: 766px) {
            display: block;
            margin: 36px 24px 0 24px;
        }
    }
    &__showcase {
        min-width: 202px;
        max-width: 280px;
        background: #FFFFFF;
        //-webkit-box-shadow: 0 4px 20px #e3e4ef;
        //box-shadow: 0 4px 20px #e3e4ef;s
        border-radius: 16px;
        overflow: hidden;

        @media (min-width: 320px) and (max-width: 766px) {
            //max-width: 280px;
            //height: 134px;
            margin-bottom: 14px;
        }
        &__title {
            font-family: 'Raleway', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 120%;
            text-align: center;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #818181;
            margin-top: 12px;
        }
        &__data {
            font-family: 'Raleway', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 120%;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #0F0F0F;
            text-align: center;
            margin-top: 4px;
        }
        &__btn {
            display: block;
            background: #0090D1;
            font-family: 'Raleway', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            text-align: center;
            color: #FFFFFF;
            padding: 10px 46px;
            border-radius: 0 0 16px 16px;
            margin-top: 16px;
            @media (min-width: 320px) and (max-width: 766px) {
                //padding: 11px 84px;
            }
        }
        &__btn-block {

            @media (min-width: 320px) and (max-width: 766px) {
                //margin-top: 16px;
            }
        }
    }
    &__showcase-mini-block {
        display: block;
    }
    &__showcase-no-mobile {
        display: block;
        @media (min-width: 320px) and (max-width: 766px) {
           display: none;
        }
    }
    &__button-box {
        margin: 34px auto 64px auto;
        @media (min-width: 320px) and (max-width: 766px) {
            margin: 20px auto 30px auto;
        }
    }
    &__button {
        width: 201px;
        display: block;
        border: 1px solid #4E4E4E;
        border-radius: 8px;
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.03em;
        padding: 8px 29.5px;
        color: #4E4E4E;

        @media (min-width: 320px) and (max-width: 766px) {
            width: 143px;
        }
    }
    &__logo {
        margin: 7px 29px;
        @media (min-width: 320px) and (max-width: 766px) {
            margin: 0 24px;
        }
    }
    &__conditions {
        display: block;
        @media (min-width: 320px) and (max-width: 766px) {
            display: flex;
            justify-content: space-evenly;
        }
    }
}
