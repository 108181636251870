$wrapper: 1186px;
$container: 984px;
$mobile: 767px;
$mobile-phone: 600px;
$container-xs: 880px;

@mixin max-width-container-xs {
    @media (max-width: #{$container-xs}) {
        @content;
    }
}

@mixin max-width-mobile-phone {
    @media (max-width: #{$mobile-phone}) {
        @content;
    }
}
@mixin max-width-mobile {
    @media (max-width: #{$mobile}) {
        @content;
    }
}
@mixin max-width-wrapper {
    @media (max-width: #{$wrapper}) {
        @content;
    }
}
@mixin portrait-laptop {
    @media(min-width: #{$mobile}) and (max-width: #{$container}) {
        @content;
    }
}
@mixin max-width-container {
    @media (max-width: #{$container}) {
        @content;
    }
}
@mixin min-width-container {
    @media (min-width: #{$container}) {
        @content;
    }
}
@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}
@function calcFluidFontSize($f-min, $f-max, $w-min, $w-max, $units: px) {
    $f-min: strip-unit($f-min);
    $f-max: strip-unit($f-max);
    $w-min: strip-unit($w-min);
    $w-max: strip-unit($w-max);

    $k: ($f-max - $f-min)/($w-max - $w-min);
    $b: $f-min - $k * $w-min;

    $b: $b + $units;

    @return calc(#{$k} * 100vw + #{$b});
}

@mixin fluidFontSize($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    font-size: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            font-size: $fallback;
        }
        font-size: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        font-size: $f-max;
    }
}
@mixin fluidLineH($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    line-height: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            line-height: $fallback;
        }
        line-height: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        line-height: $f-max;
    }
}



@mixin gridGap($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    grid-gap: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            grid-gap: $fallback;
        }
        grid-gap: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        grid-gap: $f-max;
    }
}



@mixin absRight($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    right: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            right: $fallback;
        }
        right: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        right: $f-max;
    }
}
@mixin absBottom($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    bottom: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            bottom: $fallback;
        }
        bottom: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        bottom: $f-max;
    }
}
@mixin minHeight($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    min-height: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            min-height: $fallback;
        }
        min-height: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        min-height: $f-max;
    }
}
@mixin pBottom($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    padding-bottom: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            padding-bottom: $fallback;
        }
        padding-bottom: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        padding-bottom: $f-max;
    }
}
@mixin mBottom($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    margin-bottom: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            margin-bottom: $fallback;
        }
        margin-bottom: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        margin-bottom: $f-max;
    }
}
@mixin mLeft($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    margin-left: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            margin-left: $fallback;
        }
        margin-left: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        margin-left: $f-max;
    }
}
@mixin mTop($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    margin-top: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            margin-top: $fallback;
        }
        margin-top: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        margin-top: $f-max;
    }
}
@mixin pTop($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    padding-top: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            padding-top: $fallback;
        }
        padding-top: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        padding-top: $f-max;
    }
}
@mixin Width($f-min, $f-max, $w-min, $w-max, $fallback: false) {
    width: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            width: $fallback;
        }
        width: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        width: $f-max;
    }
}
@mixin Height($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    height: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            height: $fallback;
        }
        height: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        height: $f-max;
    }
}
@mixin pLeft($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    padding-left: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            padding-left: $fallback;
        }
        padding-left: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        padding-left: $f-max;
    }
}


@mixin pRight($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    padding-right: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            padding-right: $fallback;
        }
        padding-right: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        padding-right: $f-max;
    }
}
