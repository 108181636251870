.btn {
    @include flex(center, center);

    background: none;
    box-shadow: none;
    border-radius: 4px;
    border: 1px solid transparent;
    position: relative;
    outline: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    line-height: 1;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;
    transition: all 250ms;

    height: 50px;
    padding: 0 10px;
    font-size: 16px;

    color: inherit;

    .icon, .fab, .fas {
        font-size: 12px;
    }

    .icon:first-child, 
    .fab:first-child, 
    .fas:first-child {
        margin-right: 10px;
    }
    .icon:last-child
    .fab:last-child, 
    .fas:last-child {
        margin-left: 10px;
    }

    &-icon {
        width: 26px;
        padding: 0;
        text-align: center;
        justify-content: center;
        font-size: 18px;
    }

    &-primary {
        background: #b6103f;
        color: #FFF;

        &:hover {
            background: #960830;
            box-shadow: 0 15px 20px -15px rgba(197,3,58,0.4);
        }
    }

    &-shadow {
        box-shadow: 0rem 10px 10px #000;
    }

    &-light {
        background: #EAF1FC;
        color: #8599BC;

        &:hover {
            background: #EAF1FC;
            color: #8599BC;
        }
    }

    &-icon {
        @include flex(center, center);
    }

    &-small {
        height: 20px;
        line-height: 18px;
        font-size: 11px;
        padding: 0 9px;
    }

    &-large {
        height: 50px;
        font-size: 16px;
    }

    &-rounded {
        border-radius: 100%;
    }

    &-tablet {
        border-radius: 99px;
    }

    &-bordered {
        background: transparent;
        border-color: #DCE7F7;
        color: #98B2D9;
    }

    &-icon.btn-small {
        width: 20px;
    }

    &-text {
        background: transparent;
        border: transparent;
        box-shadow: none;
        color: $color2;
    }
}

button.btn {
    display: inline-block;
}