.partner-widget {
    background: #FFFFFF;
    height: 409px;

    @media (min-width: 250px) and (max-width: 766px) {
        padding: 20px;
        margin: 20px;
    }

    &__wrapper {
        max-width: 1300px;
        padding: 40px 0;
        margin: 0 auto;
        @media (min-width: 766px) and (max-width: 1024px) {
            font-family: 'Raleway', sans-serif;
            font-size: 42px;
            line-height: 38px;
            margin-top: 120px;
            padding: 40px;
        }
    }

    &__title {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 64px;
        line-height: 120%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #182837;
        margin-top: 79px;

        @media (min-width: 250px) and (max-width: 766px) {
            font-family: 'Raleway', sans-serif;
            font-size: 32px;
            line-height: 38px;
            margin-top: 120px;
        }
        @media (min-width: 766px) and (max-width: 1024px) {
            font-size: 42px;
        }
    }

    &__partners {
        display: -webkit-box;
        display: -ms-flexbox;
        grid-auto-flow: column;
        display: grid;
        margin-top: 26px;
        grid-gap: 10px;
        overflow: auto;
        text-align: center;
    }

    &__item img {
        @media (min-width: 250px) and (max-width: 766px) {
            width: 177px;
        }
    }
}
