header {
    border-radius: 0 0 60px 60px;
    background: white;
}
.header-wrapper {
    max-width: 1300px;
    margin: 0 auto;
    position: relative;


    @media (min-width: 250px) and (max-width: 1400px) {
        height: unset;
    }

    @media (min-width: 767px) and (max-width: 1180px) {
        margin: 0 20px;
    }
}

.header-block {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 80px;

    @media (min-width: 250px) and (max-width: 1400px) {
        flex-direction: column-reverse;
        position: unset;
        align-items: center;
    }

    @media (min-width: 250px) and (max-width: 766px) {
        height: 200px;
        top: 100px;
    }
}

.header-content-left {
    @media (min-width: 250px) and (max-width: 1400px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    &__text {
        @media (min-width: 250px) and (max-width: 766px) {
            padding-bottom: 20px;
            width: 165px;
        }
    }

    &__title {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 64px;
        line-height: 120%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #182837;

        @media (min-width: 250px) and (max-width: 766px) {
            font-size: 24px;
            line-height: 30px;
            padding-bottom: 12px;
        }
    }

    &__desc {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 120%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #3D515F;
        padding: 34px 0;

        @media (min-width: 250px) and (max-width: 766px) {
            font-size: 14px;
            margin: 0 20px;
            position: relative;
            top: 100px;
        }
    }
}

.header-content-right {
    margin-top: 20px;

    @media (min-width: 250px) and (max-width: 1400px) {
        display: none;
    }
}

.header-content-statistic {
    display: flex;
    align-items: flex-end;

    @media (min-width: 250px) and (max-width: 766px) {
        width: 100%;
    }

    &__left {
        width: 120px;
        height: 153px;
        border-radius: 20px 0 0 0;
        padding: 20px 0;
        text-align: center;
        background: #FFCF52;

        @media (min-width: 250px) and (max-width: 766px) {
            height: 131px;
            flex: 0 1 auto;
            width: 50%;
            border-radius: 20px 0 0 30px;
        }
    }

    &__center {
        width: 140px;
        height: 196px;
        border-radius: 20px 0 0 0;
        padding: 20px 0;
        text-align: center;
        background: #ADE2FB;

        @media (min-width: 250px) and (max-width: 766px) {
            height: 168px;
            flex: 0 1 auto;
            width: 50%;
        }
    }

    &__right{
        width: 160px;
        height: 258px;
        border-radius: 20px 20px 0 0;
        padding: 20px 0;
        text-align: center;
        background: #A9EB75;

        @media (min-width: 250px) and (max-width: 766px) {
            height: 221px;
            flex: 0 1 auto;
            width: 50%;
            border-radius: 20px 0 30px 0;
        }
    }

    &__pers {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 700;
        text-align: center;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #011C2F;
    }

    &__pers-left {
        font-size: 32px;
        margin-top: 5px;
    }

    &__pers-center {
        font-size: 40px;
        margin-top: 5px;
    }

    &__pers-right {
        font-size: 48px;
        margin-top: 20px;
    }

    &__status {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        text-align: center;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #011C2F;
        padding-bottom: 10px;
    }

    &__status-left {
        font-size: 14px;
    }

    &__status-center {
        font-size: 16px;
    }

    &__status-right {
        font-size: 18px;
    }

    &__desc {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #3D515F;
        padding-bottom: 5px;
        margin-top: 12px;
    }

    &__req {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        text-align: center;
        color: #011C2F;
    }
}
