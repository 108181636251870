/*FLEX*/
.flex {
    display: flex !important;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: space-between;

    &-center {
        align-items: center;
        justify-content: center
    }

    &-nowrap {
        flex-wrap: nowrap
    }

    &-grow {
        flex-grow: 1;
    }
    
    &-reverse {
        flex-wrap: wrap-reverse
    }

    &-column {
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column nowrap
    }

    &-auto-height {
        flex: 1 1 auto
    }
}

.justify-content,
.jc {
    &-start {
        justify-content: flex-start
    }

    &-end {
        justify-content: flex-end
    }

    &-center {
        justify-content: center
    }

    &-between {
        justify-content: space-between
    }

    &-around {
        justify-content: space-around
    }
}

.align-items,
.ai {
    &-start {
        align-items: flex-start
    }

    &-end {
        align-items: flex-end
    }

    &-center {
        align-items: center
    }

    &-baseline {
        align-items: baseline
    }

    &-stretch {
        align-items: stretch
    }
}

.align-content,
.ac {
    &-start {
        align-content: flex-start
    }

    &-end {
        align-content: flex-end
    }

    &-center {
        align-content: center
    }

    &-between {
        align-content: space-between
    }

    &-around {
        align-content: space-around
    }

    &-stretch {
        align-content: stretch
    }
}

.direction,
.d {
    &-row {
        flex-direction: row
    }

    &-row-reverse {
        flex-direction: row-reverse
    }

    &-column {
        flex-direction: column
    }

    &-column-reverse {
        flex-direction: column-reverse
    }
}
